import { inject } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/partners.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { PartnerService } from '../../services';
import { Go } from 'src/app/store';

export const LoadPartners$ = createEffect(
  (actions$ = inject(Actions), service = inject(PartnerService)) => {
    return actions$.pipe(
      ofType(actions.LoadPartners),
      switchMap(() =>
        service.getPartners().pipe(
          map(Partner => actions.LoadPartnersSuccess({ Partner })),
          catchError(error => of(actions.LoadPartnersFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const LoadPartnerById$ = createEffect(
  (actions$ = inject(Actions), service = inject(PartnerService)) => {
    return actions$.pipe(
      ofType(actions.LoadPartnerById),
      switchMap(({ id }) =>
        service.getPartnerById(id).pipe(
          map(Item => actions.LoadPartnerByIdSuccess({ Item })),
          catchError(error => of(actions.LoadPartnerByIdFail({ error })))
        )
      )
    );
  },
  { functional: true }
);

export const LoadPartnerByIdFail$ = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(actions.LoadPartnerByIdFail),
      map(({ error }) => {
        return Go({ path: ['/' + error?.status ? error.status : '404'], extras: { skipLocationChange: true } });
      })
    );
  },
  { functional: true }
);
