import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Partner } from '../models';

@Injectable({
  providedIn: 'root',
})
export class PartnerService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getPartners() {
    return this.http.get<Partner[]>(`${this.BASE_URL}api/partners`);
  }

  getPartnerById(id: number) {
    return this.http.get<Partner>(`${this.BASE_URL}api/partners/${id}`);
  }
}
