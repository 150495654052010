import { createAction, props } from '@ngrx/store';
import { Partner } from '../../models';

export const LoadPartners = createAction('[Partner] Load Partners');
export const LoadPartnersSuccess = createAction('[Partner] Load Partners Success', props<{ Partner: Partner[] }>());
export const LoadPartnersFail = createAction('[Partner] Load Partners Fail', props<{ error: any }>());

export const LoadPartnerById = createAction('[Partner] Load Partner by Id', props<{ id: number }>());
export const LoadPartnerByIdSuccess = createAction('[Partner] Load Partner by Id Success', props<{ Item: Partner }>());
export const LoadPartnerByIdFail = createAction('[Partner] Load Partner by Id Fail', props<{ error: any }>());
