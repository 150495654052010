import * as fromPartner from './partners.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'partners';

export interface PartnerState {
  Partner: fromPartner.PartnerState;
}

export const reducers: ActionReducerMap<PartnerState> = {
  Partner: fromPartner.PartnerReducer,
};

export const getPartnerState = createFeatureSelector<PartnerState>(featureKey);

export const metaReducers: MetaReducer<PartnerState>[] = !environment.production ? [] : [];
