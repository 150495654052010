import { createReducer, on } from '@ngrx/store';
import { Partner } from '../../models';
import * as pageActions from '../actions/partners.action';
import { ToEntities } from '@teamfoster/sdk/array-utility';

export interface PartnerState {
  entities: { [id: number]: Partner };
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialState: PartnerState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const PartnerReducer = createReducer(
  initialState,

  on(pageActions.LoadPartners, (state: PartnerState) => {
    return {
      ...state,
      loading: true,
      loaded: false,
    };
  }),
  on(pageActions.LoadPartnersSuccess, (state: PartnerState, { Partner }) => {
    const entities = ToEntities(Partner, 'id', state.entities);
    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadPartnersFail, (state: PartnerState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getPartnerEntities = (state: PartnerState) => state.entities;
export const getPartnerLoading = (state: PartnerState) => state.loading;
export const getPartnerLoaded = (state: PartnerState) => state.loaded;
export const getPartnerError = (state: PartnerState) => state.error;
