import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromPartners from '../reducers/partners.reducer';

export const getPartnersState = createSelector(fromFeature.getPartnerState, (state: fromFeature.PartnerState) => state.Partner);

export const getPartnerEntities = createSelector(getPartnersState, fromPartners.getPartnerEntities);

export const getAllPartners = createSelector(getPartnerEntities, entities => {
  return Object.keys(entities).map(id => entities[+id]);
});

export const getPartnerLoading = createSelector(getPartnersState, fromPartners.getPartnerLoading);
export const getPartnerLoaded = createSelector(getPartnersState, fromPartners.getPartnerLoaded);
export const getPartnerError = createSelector(getPartnersState, fromPartners.getPartnerError);
